.feature {
    margin-bottom: 70px;
    margin-top: 70px;
    scroll-margin-top: 138px;
    @media screen and (min-width: 900px) {
        scroll-margin-top: 120px;
    }
}
.split {
    @media screen and (min-width: 980px) {
        display: flex;
        flex-direction: row;
        gap: 0 40px;
    }
    &.reverse {
        @media screen and (min-width: 980px) {
            flex-direction: row-reverse;
        }
    }
}
.details {
    position: relative;
    margin-bottom: 25px;
    @media screen and (min-width: 980px) {
        flex-basis: 50%;
        min-width: 300px;
        margin-bottom: 0;
    }
    article {
        @media screen and (min-width: 980px) {
            max-width: 470px;
        }
    }
}
.picture {
    text-align: center;
    @media screen and (min-width: 980px) {
        text-align: initial;
        flex-basis: 50%;
        min-width: 300px;
    }
    img {
        width: 100%;
        height: auto;
        min-width: 300px;
        max-width: fit-content;
    }
}
.middle {
    align-self: center;
}
.start {
    align-self: start;
}
.end {
    align-self: end;
}
