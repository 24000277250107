.desc {
    &.center {
        text-align: center;
    }
    &.left {
        text-align: left;
    }
    &.right {
        text-align: right;
    }
}
.paragraph {
    font-family: var(--roboto);
    font-weight: 400;
    font-size: 18px;
    line-height: 1.6;
    letter-spacing: 0.025em;
    margin: 0 0 27px;
    + ul,
    + .intro {
        margin-top: 25px;
    }
    :where(a) {
        color: var(--primary-link-color);
        text-decoration: none;
        &:hover {
            color: var(--primary-link-hover-color);
        }
    }
}
.subheader {
    @media screen and (min-width: 980px) {
        max-width: 780px;
        margin: 0 auto 40px;
    }
}
.intro {
    font-family: var(--roboto);
    font-size: 18px;
    font-weight: 700;
    color: #202730;
    margin: 25px 0;
}
.preheader {
    font-family: var(--roboto);
    font-size: 13px;
    letter-spacing: 0.01em;
    font-weight: 700;
    color: #7990a1;
    text-transform: uppercase;
    display: block;
    margin: 0 0 7px;
}
.link {
    margin-top: 40px;
}
.list {
    list-style-position: inside;
    margin-bottom: 25px;
    margin-top: -16px;
    padding: 0;
    :where(li) {
        font-family: var(--roboto);
        font-weight: 400;
        font-size: 18px;
        line-height: 1.6;
        margin: 0 0 23px;
        &::marker {
            color: var(--primary-brand-color-400);
        }
        &:first-child {
            margin-top: 0;
        }
    }
    :where(a) {
        color: var(--primary-link-color);
        text-decoration: none;
        &:hover {
            color: var(--primary-link-hover-color);
        }
    }
}
.icons {
    list-style: none;
    padding: 0;
    margin: 0 0 30px;
    li {
        position: relative;
        padding-left: 45px;
        margin-top: 32px;
        &:first-child {
            margin-top: 0;
        }
    }
    svg {
        position: absolute;
        top: 0;
        left: 0;
    }
}
