.details {
    &[open] {
        svg {
            transform: rotate(180deg);
        }
    }
}
.summary {
    color: #202730;
    font-size: 22px;
    font-weight: 700;
    font-family: var(--source);
    line-height: 1.5;
    letter-spacing: 0.025em;
    cursor: pointer;
    margin: 22px 0 13px;
    list-style: none;
    &::-webkit-details-marker {
        display: none;
    }
    &:focus {
        outline: none;
    }
    &:focus-visible {
        color: var(--primary-brand-color-400);
    }
    svg {
        width: 15px;
        margin-left: 7px;
        margin-bottom: -2px;
        vertical-align: baseline;
    }
}
.article {
    color: #202730;
    font-family: var(--roboto);
    margin: 0;
    padding: 0 0 10px;
    font-size: 18px;
    line-height: 1.667;
    letter-spacing: 0.025em;
    ul {
        margin: 0;
        padding: 0;
        list-style: none;
    }
    li {
        margin: 0 0 27px;
    }
    p {
        font-weight: 400;
        font-size: 18px;
        line-height: 1.6;
        letter-spacing: 0.025em;
        margin: 0 0 25px;
        &:last-child {
            margin-bottom: 0;
        }
        & + ul {
            margin-top: 10px;
        }
    }
    a {
        font-weight: 700;
        text-decoration: none;
        color: var(--primary-link-color);
        &:hover {
            color: var(--primary-link-hover-color);
        }
    }
}
