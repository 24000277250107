.card {
    color: #202730;
    font-family: var(--roboto);
    background: #fff;
    border-radius: 6px;
    padding: 27px 22px;
    box-sizing: border-box;
    min-height: 302px;
    max-width: 560px;
    width: 100%;
    position: relative;
    box-shadow: 0 7px 45px 0 rgba(0, 0, 0, 0.1);
    @media screen and (min-width: 768px) {
        height: 100%;
        display: flex;
        flex-wrap: nowrap;
        padding: 38px 38px 30px;
    }
    &:hover .more {
        color: var(--primary-link-hover-color);
    }
}
.border {
    box-shadow: none;
    border: 1px solid #cedbe5;
}
.image {
    margin: 0 0 30px;
    @media screen and (min-width: 768px) {
        flex: 0 0 150px;
        margin: 0 30px 0 0;
    }
    img {
        display: block;
        height: auto;
        max-width: 100%;
        margin: 0 auto;
        vertical-align: bottom;
    }
}
.desc {
    text-align: center;
    margin-bottom: 13px;
    @media screen and (min-width: 768px) {
        text-align: left;
        &.bottomlink {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
    }
    h3 {
        font-family: var(--source);
        font-size: 23px;
        font-weight: 900;
        margin: 0 0 18px;
        a {
            color: inherit;
            text-decoration: none;
            &::before {
                content: '';
                bottom: 0;
                left: 0;
                right: 0;
                top: 0;
                position: absolute;
                z-index: 1;
            }
        }
    }
    p {
        font-size: 16px;
        line-height: 1.44;
        margin: 0 0 23px;
        @media screen and (min-width: 768px) {
            letter-spacing: 0.025em;
        }
    }
}
