.more {
    --roboto: 'RobotoFlex', sans-serif;
    text-transform: uppercase;
    font-family: var(--roboto);
    font-weight: 700;
    letter-spacing: 0.035em;
    display: inline-block;
    text-decoration: none;
    color: var(--primary-link-color);
    transition: color 0.3s ease-out;
    &:hover,
    &:active,
    &:focus {
        color: var(--primary-link-hover-color);
    }

    &:focus:not(:focus-visible) {
        outline: none;
    }
}
.big {
    font-size: 18px;
}
.small {
    font-size: 16px;
}
.blue {
    color: var(--primary-link-color);
    &:hover,
    &:active,
    &:focus {
        color: var(--primary-link-hover-color);
    }
}

.feature-blue {
    font-family: var(--rubik);
    color: var(--primary-brand-color-600);
    font-weight: 700;
    text-transform: unset;
    letter-spacing: 0;
    &:hover,
    &:active,
    &:focus {
        color: #008bda;
        text-decoration: underline;
    }
}

.yellow {
    color: var(--secondary-brand-color-400);

    &:hover,
    &:active,
    &:focus {
        color: var(--secondary-brand-color-600);
    }
}
.orange {
    color: var(--orange-color);
    &:hover,
    &:active,
    &:focus {
        color: var(--orange-color-hover);
    }
}
.white {
    color: #fff;
    &:hover,
    &:active,
    &:focus {
        color: #f8fafc;
    }
}
