.carousel {
    --base-fonts: 'Helvetica', 'Arial', sans-serif;
    --source: 'Source Sans 3', var(--base-fonts);
    --roboto: 'RobotoFlex', var(--base-fonts);
    position: relative;
    ul {
        display: flex;
        list-style: none;
        padding: 0;
        margin: 0 auto;
        position: relative;
        gap: 40px;
        justify-content: center;
        @media screen and (min-width: 900px) {
            gap: 90px;
        }

        &.animate-from-right {
            animation-name: carouselFromRight;
            animation-duration: 0.3s;
        }

        &.animate-from-left {
            animation-name: carouselFromLeft;
            animation-duration: 0.3s;
        }
    }

    li {
        align-self: stretch;
        margin-bottom: 20px;
        display: flex;
        justify-content: center;
        opacity: 0.23;
        pointer-events: none;
        flex: 1 0 75%;
        min-width: 300px;
        @media screen and (min-width: 768px) {
            min-width: 640px;
        }
        @media screen and (min-width: 900px) {
            flex: 1 0 640px;
            min-width: 0;
        }

        &.current {
            z-index: 1;
            opacity: 1;
            filter: none;
            pointer-events: all;

            .prev,
            .next {
                display: block;
            }
        }
    }

    .prev,
    .next {
        position: absolute;
        top: calc(50% - 10px);
        transform-origin: center;
        cursor: pointer;
        z-index: 1;
        padding: 10px;
        svg {
            width: 20px;
            height: 25px;
            opacity: 1;
            transform: none;
        }
    }
    .prev {
        left: -4px;
        @media screen and (min-width: 768px) {
            left: 20px;
        }
        @media screen and (min-width: 900px) {
            left: calc(50% - 365px);
        }
        transform: translate(-50%, -50%) rotateZ(90deg);
    }
    .next {
        right: -4px;
        @media screen and (min-width: 768px) {
            right: 20px;
        }
        @media screen and (min-width: 900px) {
            right: calc(50% - 365px);
        }
        transform: translate(50%, -50%) rotateZ(-90deg);
    }
}
@keyframes carouselFromRight {
    from {
        transform: translateX(680px);
    }
    to {
        transform: translateX(0);
    }
}
@keyframes carouselFromLeft {
    from {
        transform: translateX(-680px);
    }
    to {
        transform: translateX(0);
    }
}
