.flip {
    &.start > div:nth-child(odd) > div {
        flex-direction: row-reverse;
    }
    &.end > div:nth-child(even) > div {
        flex-direction: row-reverse;
    }
}
.start:not(.flip) > div > div {
    flex-direction: row-reverse;
}
.end:not(.flip) > div > div {
    flex-direction: row;
}
