.feature-item {
    display: flex;
    flex-wrap: nowrap;
    margin: 20px 0;
    h3 {
        font-weight: 700;
        font-family: var(--source);
        font-size: 22px;
        letter-spacing: 0.025em;
        margin: 0 0 7px;
    }
    p {
        font-family: var(--roboto);
        color: #202730;
        font-size: 18px;
        font-weight: 400;
        letter-spacing: 0.03em;
        line-height: 1.4;
        margin: 0;
        @media screen and (min-width: 768px) {
            max-width: 395px;
        }
        &.white {
            color: #fff;
        }
        &.grey {
            color: #7990a1;
        }
    }
    a {
        color: var(--primary-link-color);
        text-decoration: none;
        &:hover {
            color: var(--primary-link-hover-color);
        }
    }
}
.icon {
    margin-right: 27px;
    width: 64px;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5px;
}
