.split {
    margin-bottom: 50px;
    @media screen and (min-width: 980px) {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr;
        display: grid;
        margin-bottom: 80px;
        &.reverse {
            .desc {
                margin-left: 10px;
            }
            .picture,
            .items {
                order: -1;
            }
        }
        &.center {
            .desc,
            .picture,
            .items {
                display: flex;
                flex-direction: column;
                justify-content: center;
            }
        }
    }
    &:last-child {
        margin-bottom: 0;
    }
}
.picture {
    margin: 0;
    padding-top: 30px;
    @media screen and (min-width: 980px) {
        padding-top: 0;
    }
    img {
        display: block;
        margin: 0 auto;
        max-width: 100%;
        height: auto;
        @media screen and (min-width: 980px) {
            position: relative;
        }
    }
    > div {
        margin: 0 auto;
    }
}
.items {
    margin: 0;
    padding-top: 30px;
    @media screen and (min-width: 980px) {
        padding-top: 0;
    }
}
