@layer defaults, theme;
@layer defaults {
    .label {
        margin-bottom: 5px;
        span {
            border-radius: 15px;
            font-family: var(--roboto);
            font-size: 12px;
            font-weight: 700;
            letter-spacing: 0.1em;
            padding: 3px 12px;
            position: relative;
            z-index: 2;
            border: 1px solid #202730;
            &.blue {
                background: var(--primary-brand-color-400);
                color: #fff;
                border: 0;
            }
            &.yellow {
                background: var(--secondary-brand-color-400);
                color: #202730;
                border: 0;
            }
            &.orange {
                background: var(--orange-color);
                color: var(--orange-button-text);
                border: 0;
            }
            &.navy {
                background: #020730;
                color: #fff;
                border: 0;
            }
            &.green {
                background: var(--green-color);
                color: var(--green-button-text);
                border: 0;
            }
        }
    }
}
