.info {
    margin-top: 30px;
    display: flex;
    align-items: center;
}
.icon {
    padding-right: 20px;
    svg {
        height: 44px;
        width: auto;
    }
}
.text {
    font-family: var(--roboto);
    font-size: 16px;
    line-height: 1.4;
    font-style: italic;
    letter-spacing: 0.025em;
    color: var(--primary-text-color-200);
    a {
        font-weight: 500;
    }
}
