.manual {
    margin: 0;
    padding: 0;
    list-style: none;
    counter-reset: counter;
    text-align: left;
    overflow: hidden;
    @media screen and (min-width: 980px) {
        padding-top: 10px;
    }
}
@media screen and (min-width: 980px) {
    .left {
        text-align: left;
    }
    .right {
        text-align: right;
    }
}
.step {
    margin-bottom: 50px;
    @media screen and (min-width: 980px) {
        margin-bottom: 70px;
    }
    &:last-child {
        margin-bottom: 0;
        @media screen and (min-width: 980px) {
            h3::before {
                content: '';
                position: absolute;
                top: 42px;
                left: calc(100% + 66px);
                height: 700px;
                width: 2px;
                background: #f7fdff;
            }
        }
    }
    @media screen and (min-width: 980px) {
        &:first-child h3::after {
            content: '';
            position: absolute;
            top: 44px;
            left: calc(100% + 66px);
            height: 2000px;
            width: 2px;
            background: url('./dot.svg') 0 center repeat-y;
            background-size: 2px 12px;
        }
    }
}
.wrap {
    @media screen and (min-width: 980px) {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr;
        grid-gap: 0 55px;
        display: grid;
    }
}
.bullets {
    h3 {
        &::before {
            width: 50px;
            height: 50px;
            counter-increment: counter;
            content: counter(counter);
            font-family: var(--source);
            font-size: 22px;
            font-weight: 700;
            color: #fff;
            background: var(--primary-btn-bg-color);
            border-radius: 30px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            margin: 0 10px 0 0;
        }
        @media screen and (min-width: 980px) {
            &::before {
                margin: 0;
                position: absolute;
                top: -3px;
                left: calc(100% + 41px);
            }
        }
    }
}
.title {
    position: relative;
    svg {
        width: 50px;
        height: 50px;
        display: inline-block;
        vertical-align: middle;
        margin: 0 10px 0 0;
        @media screen and (min-width: 980px) {
            width: 70px;
            height: 70px;
            margin: 0;
            position: absolute;
            top: -12px;
            left: calc(100% + 32px);
        }
    }
}
.texts,
.picture {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.texts {
    margin-bottom: 30px;
    @media screen and (min-width: 980px) {
        max-width: 444px;
        margin: 0 38px 0 auto;
    }
    h3 {
        font-weight: 700;
        font-family: var(--source);
        font-size: 25px;
        letter-spacing: 0;
        margin: 0 0 20px;
        @media screen and (min-width: 768px) {
            font-size: 30px;
        }
    }
    p {
        font-family: var(--source);
        font-size: 20px;
        font-weight: 400;
        letter-spacing: 0;
        line-height: 1.5;
        margin: 0 0 30px;
    }
}
.picture {
    margin: 0;
    @media screen and (min-width: 980px) {
        margin-left: 20px;
    }
    img {
        display: block;
        margin: 0 auto;
        max-width: 100%;
        height: auto;
        @media screen and (min-width: 980px) {
            padding-right: 20px;
        }
    }
}
