.blue {
    margin: 0 0 40px;
    padding: 20px;
    box-sizing: border-box;
    border: 2px solid #00baff;
    color: #202730;
    font-family: var(--roboto);
    background: #f7fdff;
    border-radius: 6px;
    text-align: left;
    @media screen and (min-width: 768px) {
        padding: 38px 58px;
    }
    h3 {
        font-weight: 700;
        font-family: var(--source);
        font-size: 25px;
        letter-spacing: 0.025em;
        margin: 0 0 28px;
        @media screen and (min-width: 768px) {
            font-size: 30px;
        }
    }
    p {
        font-family: var(--roboto);
        font-size: 18px;
        font-weight: 400;
        letter-spacing: 0.03em;
        line-height: 1.5;
        margin: 0 0 30px;
        &:last-child {
            margin-bottom: 0;
        }
    }
}
