.container {
    color: #fff;
    font-family: var(--roboto);
    margin: 0 -20px;
    max-width: 1140px;
    background: #00baff url('./maz.svg') center no-repeat;
    background-size: auto 100%;
    @media only screen and (min-width: 600px) {
        margin: 0 auto;
        max-width: 600px;
        border-radius: 8px;
    }
    @media only screen and (min-width: 980px) {
        display: flex;
        flex-wrap: nowrap;
        max-width: 1140px;
    }
    form {
        width: 100%;
        max-width: 516px;
        margin: 30px auto 0;
        position: relative;
        @media only screen and (min-width: 980px) {
            display: flex;
            margin-left: 0;
        }
        fieldset {
            margin: 0;
            padding: 0;
            border: 0;
            @media only screen and (min-width: 980px) {
                flex-grow: 1;
                margin-right: -30px;
            }
        }
        input {
            font-family: var(--roboto);
            border: none;
            height: 60px;
            width: 100%;
            padding: 0 35px 0 30px;
            box-sizing: border-box;
            color: #202730;
            border-radius: 100px;
            margin-bottom: 20px;
            font-size: 20px;
            font-weight: 500;
            @media only screen and (min-width: 980px) {
                border-radius: 100px 0 0 100px;
                flex-grow: 1;
                margin-bottom: 0;
            }
            &:focus {
                outline: none;
            }
            --placeholder-text-color: #7a90a1;
        }
        small {
            position: absolute;
            bottom: -10px;
            transform: translateY(100%);
            color: #fff;
            font-size: 13px;
            font-family: var(--roboto);
            letter-spacing: 0.05em;
            font-weight: 400;
        }
    }
}
.formwrapper {
    flex-grow: 1;
    box-sizing: border-box;
    padding: 30px 20px 120px;
    text-align: center;
    margin: 0;
    min-height: 382px;
    @media screen and (min-width: 768px) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 30px 20px 120px;
    }
    @media only screen and (min-width: 980px) {
        padding: 16px 100px 22px 72px;
        text-align: left;
    }
}
.title {
    font-family: var(--source);
    font-weight: 900;
    line-height: 1.25;
    font-size: 40px;
    letter-spacing: 0.02em;
    margin: 6px 0 0;
}
.text {
    font-family: var(--roboto);
    font-weight: 500;
    line-height: 1.25;
    font-size: 18px;
    letter-spacing: 0.02em;
    margin: 0 0 10px;
    padding: 12px 0 0;
}
.image {
    padding: 100px 20px 20px;
    background: #00a2ff;
    position: relative;
    flex-grow: 1;
    box-sizing: border-box;
    border-radius: 0 0 8px 8px;
    @media only screen and (min-width: 980px) {
        border-radius: 0 8px 8px 0;
        padding: 20px 30px 20px 115px;
        display: flex;
        align-items: center;
        max-width: 350px;
    }
    @media only screen and (min-width: 1060px) {
        max-width: 436px;
        padding-right: 50px;
    }
    img {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(calc(4px - 50%), calc(6px - 50%));
        @media only screen and (min-width: 980px) {
            top: 50%;
            left: 0;
        }
    }
}
.blockquote {
    text-align: left;
    margin: 0;
}
.cite {
    font-size: 18px;
    margin: 0 0 28px;
    font-weight: 500;
    font-style: italic;
    line-height: 1.6;
    letter-spacing: 0.015em;
}
.medium {
    .cite {
        font-size: 22px;
        line-height: 1.3;
        letter-spacing: 0;
    }
}
.big {
    .cite {
        font-size: 27px;
        line-height: 1.3;
        letter-spacing: 0;
    }
}
.author {
    margin: 0;
    padding: 0;
    p {
        margin: 0;
    }
    strong {
        font-size: 18px;
        display: block;
        margin-bottom: 8px;
    }
    span {
        font-size: 15px;
        letter-spacing: 0.02em;
    }
}
.btn {
    cursor: pointer;
    font-family: var(--roboto);
    box-sizing: border-box;
    text-decoration: none;
    text-transform: uppercase;
    transition: background 0.2s ease-in-out;
    border-radius: 50px;
    padding: 19px 38px;
    border: 0;
    font-weight: 700;
    font-size: 18px;
    display: inline-block;
    height: 100%;
    letter-spacing: 0;
    white-space: nowrap;
    line-height: 1;
    color: #202730;
    background: var(--secondary-brand-color-400);
    @media only screen and (min-width: 980px) {
        padding: 14px;
    }
    &:hover {
        background: var(--secondary-brand-color-600);
    }
}
.error {
    margin-bottom: 20px;
    font-size: 14px;
    background: #ec5c5c;
    border-radius: 3px;
    padding: 0.3em 0.7em;
    @media only screen and (min-width: 980px) {
        position: absolute;
        margin-top: 5px;
        margin-left: 30px;
    }
}
