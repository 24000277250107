.FaqQuestionAndAnswer_details__0U3Ys[open] svg {
            transform: rotate(180deg);
        }
.FaqQuestionAndAnswer_summary__G2a69 {
    color: #202730;
    font-size: 22px;
    font-weight: 700;
    font-family: var(--source);
    line-height: 1.5;
    letter-spacing: 0.025em;
    cursor: pointer;
    margin: 22px 0 13px;
    list-style: none;
}
.FaqQuestionAndAnswer_summary__G2a69::-webkit-details-marker {
        display: none;
    }
.FaqQuestionAndAnswer_summary__G2a69:focus {
        outline: none;
    }
.FaqQuestionAndAnswer_summary__G2a69:focus-visible {
        color: var(--primary-brand-color-400);
    }
.FaqQuestionAndAnswer_summary__G2a69 svg {
        width: 15px;
        margin-left: 7px;
        margin-bottom: -2px;
        vertical-align: baseline;
    }
.FaqQuestionAndAnswer_article__mZkWP {
    color: #202730;
    font-family: var(--roboto);
    margin: 0;
    padding: 0 0 10px;
    font-size: 18px;
    line-height: 1.667;
    letter-spacing: 0.025em;
}
.FaqQuestionAndAnswer_article__mZkWP ul {
        margin: 0;
        padding: 0;
        list-style: none;
    }
.FaqQuestionAndAnswer_article__mZkWP li {
        margin: 0 0 27px;
    }
.FaqQuestionAndAnswer_article__mZkWP p {
        font-weight: 400;
        font-size: 18px;
        line-height: 1.6;
        letter-spacing: 0.025em;
        margin: 0 0 25px;
    }
.FaqQuestionAndAnswer_article__mZkWP p:last-child {
            margin-bottom: 0;
        }
.FaqQuestionAndAnswer_article__mZkWP p + ul {
            margin-top: 10px;
        }
.FaqQuestionAndAnswer_article__mZkWP a {
        font-weight: 700;
        text-decoration: none;
        color: var(--primary-link-color);
    }
.FaqQuestionAndAnswer_article__mZkWP a:hover {
            color: var(--primary-link-hover-color);
        }

.Article_desc__Sx1or.Article_center__j71Jj {
        text-align: center;
    }
    .Article_desc__Sx1or.Article_left__ji7Ei {
        text-align: left;
    }
    .Article_desc__Sx1or.Article_right__Zgn3N {
        text-align: right;
    }
.Article_paragraph__JTY6K {
    font-family: var(--roboto);
    font-weight: 400;
    font-size: 18px;
    line-height: 1.6;
    letter-spacing: 0.025em;
    margin: 0 0 27px;
}
.Article_paragraph__JTY6K + ul,
    .Article_paragraph__JTY6K + .Article_intro__J9bBv {
        margin-top: 25px;
    }
.Article_paragraph__JTY6K :where(a) {
        color: var(--primary-link-color);
        text-decoration: none;
    }
.Article_paragraph__JTY6K :where(a):hover {
            color: var(--primary-link-hover-color);
        }
@media screen and (min-width: 980px) {
.Article_subheader__ajhyP {
        max-width: 780px;
        margin: 0 auto 40px
}
    }
.Article_intro__J9bBv {
    font-family: var(--roboto);
    font-size: 18px;
    font-weight: 700;
    color: #202730;
    margin: 25px 0;
}
.Article_preheader__U0j64 {
    font-family: var(--roboto);
    font-size: 13px;
    letter-spacing: 0.01em;
    font-weight: 700;
    color: #7990a1;
    text-transform: uppercase;
    display: block;
    margin: 0 0 7px;
}
.Article_link__kLhCL {
    margin-top: 40px;
}
.Article_list__p5g0k {
    list-style-position: inside;
    margin-bottom: 25px;
    margin-top: -16px;
    padding: 0;
}
.Article_list__p5g0k :where(li) {
        font-family: var(--roboto);
        font-weight: 400;
        font-size: 18px;
        line-height: 1.6;
        margin: 0 0 23px;
    }
.Article_list__p5g0k :where(li)::marker {
            color: var(--primary-brand-color-400);
        }
.Article_list__p5g0k :where(li):first-child {
            margin-top: 0;
        }
.Article_list__p5g0k :where(a) {
        color: var(--primary-link-color);
        text-decoration: none;
    }
.Article_list__p5g0k :where(a):hover {
            color: var(--primary-link-hover-color);
        }
.Article_icons__kQiMR {
    list-style: none;
    padding: 0;
    margin: 0 0 30px;
}
.Article_icons__kQiMR li {
        position: relative;
        padding-left: 45px;
        margin-top: 32px;
    }
.Article_icons__kQiMR li:first-child {
            margin-top: 0;
        }
.Article_icons__kQiMR svg {
        position: absolute;
        top: 0;
        left: 0;
    }

.FeatureItem_feature-item__x1_KY {
    display: flex;
    flex-wrap: nowrap;
    margin: 20px 0;
}
    .FeatureItem_feature-item__x1_KY h3 {
        font-weight: 700;
        font-family: var(--source);
        font-size: 22px;
        letter-spacing: 0.025em;
        margin: 0 0 7px;
    }
    .FeatureItem_feature-item__x1_KY p {
        font-family: var(--roboto);
        color: #202730;
        font-size: 18px;
        font-weight: 400;
        letter-spacing: 0.03em;
        line-height: 1.4;
        margin: 0;
    }
    @media screen and (min-width: 768px) {
    .FeatureItem_feature-item__x1_KY p {
            max-width: 395px
    }
        }
    .FeatureItem_feature-item__x1_KY p.FeatureItem_white__v6H__ {
            color: #fff;
        }
    .FeatureItem_feature-item__x1_KY p.FeatureItem_grey__cbU_b {
            color: #7990a1;
        }
    .FeatureItem_feature-item__x1_KY a {
        color: var(--primary-link-color);
        text-decoration: none;
    }
    .FeatureItem_feature-item__x1_KY a:hover {
            color: var(--primary-link-hover-color);
        }
.FeatureItem_icon__sdJsS {
    margin-right: 27px;
    width: 64px;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5px;
}

.Button_btn__C5KPZ {
    display: inline-block;
    box-sizing: border-box;
    border-radius: 50px;
    padding: 1.05em 2.1em;
    line-height: 1;
    font-size: 18px;
    font-family: var(--roboto);
    font-weight: 600;
    text-decoration: none;
    text-align: center;
    background: #fff;
    border: 2px solid var(--primary-btn-bg-color);
    color: var(--primary-btn-bg-color);
    transition: background-color 0.3s ease-in-out, border-color 0.3s ease-in-out;
    cursor: pointer;
    letter-spacing: 0.1em;
}
    .Button_btn__C5KPZ.Button_text__kT_W0 {
        background: transparent;
        border: none;
        padding: 1.05em 0;
    }
    .Button_btn__C5KPZ.Button_text__kT_W0.Button_white__vIFsA {
            color: var(--primary-brand-color-alternate-400);
        }
    .Button_btn__C5KPZ.Button_text__kT_W0.Button_white__vIFsA:hover,
            .Button_btn__C5KPZ.Button_text__kT_W0.Button_white__vIFsA:active,
            .Button_btn__C5KPZ.Button_text__kT_W0.Button_white__vIFsA:focus {
                background: transparent;
                border: none;
            }
    .Button_btn__C5KPZ.Button_contained__0ZMGy {
        border-radius: 50px;
        padding: 1.05em 2.1em;
        background: var(--primary-brand-color-alternate-400);
        border: 2px solid var(--primary-btn-bg-color);
        color: var(--primary-btn-bg-color);
    }
    .Button_btn__C5KPZ.Button_solid__8BoBa {
        border-radius: 50px;
        background: var(--primary-brand-color-400);
        border: none;
        color: var(--primary-brand-color-alternate-400);
    }
    .Button_btn__C5KPZ.Button_solidInverse__Y4AR1 {
        border-radius: 50px;
        background: var(--primary-brand-color-alternate-400);
        border: none;
        color: var(--primary-brand-color-400);
    }
    .Button_btn__C5KPZ.Button_solidInverse__Y4AR1:hover,
        .Button_btn__C5KPZ.Button_solidInverse__Y4AR1:active,
        .Button_btn__C5KPZ.Button_solidInverse__Y4AR1:focus {
            background: var(--primary-brand-color-alternate-400);
            color: var(--primary-btn-hover-bg-color);
        }
    .Button_btn__C5KPZ.Button_outlined__t_qNN {
        border-radius: 50px;
        padding: 1.05em 2.1em;
        background: transparent;
        border: 2px solid var(--primary-btn-bg-color);
        color: var(--primary-btn-bg-color);
    }
    .Button_btn__C5KPZ.Button_outlined__t_qNN.Button_white__vIFsA {
            border-color: var(--primary-brand-color-alternate-400);
            color: var(--primary-brand-color-alternate-400);
        }
    .Button_btn__C5KPZ.Button_outlined__t_qNN.Button_white__vIFsA:hover,
            .Button_btn__C5KPZ.Button_outlined__t_qNN.Button_white__vIFsA:active,
            .Button_btn__C5KPZ.Button_outlined__t_qNN.Button_white__vIFsA:focus {
                border: 2px solid var(--primary-btn-bg-color);
            }
    .Button_btn__C5KPZ:hover,
    .Button_btn__C5KPZ:active,
    .Button_btn__C5KPZ:focus {
        background-color: var(--primary-btn-hover-bg-color);
        border-color: var(--primary-btn-hover-bg-color);
        color: var(--primary-btn-hover-text-color);
    }
    .Button_btn__C5KPZ:focus:not(:focus-visible) {
        outline: none;
    }

.Button_blue__KLMwL {
    color: #fff;
    background: var(--primary-brand-color-400);
    border-color: var(--primary-brand-color-400);
}

.Button_blue__KLMwL:hover,
    .Button_blue__KLMwL:active,
    .Button_blue__KLMwL:focus {
        background: var(--primary-brand-color-600);
        border-color: var(--primary-brand-color-600);
    }
.Button_yellow__JjIRa {
    background: var(--secondary-brand-color-400);
    border-color: var(--secondary-brand-color-400);
    color: #202730;
}
.Button_yellow__JjIRa:hover,
    .Button_yellow__JjIRa:active,
    .Button_yellow__JjIRa:focus {
        background: var(--secondary-brand-color-600);
        border-color: var(--secondary-brand-color-600);
        color: #202730;
    }
.Button_orange__c8kbB {
    background: var(--orange-color);
    border-color: var(--orange-color);
    color: var(--orange-button-text);
}
.Button_orange__c8kbB:hover,
    .Button_orange__c8kbB:active,
    .Button_orange__c8kbB:focus {
        background: var(--orange-color-hover);
        border-color: var(--orange-color-hover);
        color: var(--orange-button-text);
    }
.Button_navy__4xpM_ {
    background: #020730;
    border-color: #020730;
    color: #fff;
}
.Button_navy__4xpM_:hover,
    .Button_navy__4xpM_:active,
    .Button_navy__4xpM_:focus {
        background: #030d5c;
        border-color: #030d5c;
    }
.Button_green__1cNYG {
    background: var(--green-color);
    border-color: var(--green-color);
    color: var(--green-button-text);
}

.Button_green__1cNYG:hover,
.Button_green__1cNYG:active,
.Button_green__1cNYG:focus {
    background: var(--green-color-hover);
    border-color: var(--green-color-hover);
    color: var(--green-button-text);
}
.Button_xlarge__xhTZd {
    font-size: 24px;
    padding: max(0.85em, 18.9px) max(1.7em, 37.8px);
    letter-spacing: 0;
}
.Button_large__jx6jn {
    font-size: 18px;
    padding: max(0.85em, 18.9px) max(1.7em, 37.8px);
    letter-spacing: 0;
}
.Button_medium__oOcQY {
    font-size: 18px;
    padding: max(1.1em, 14px) max(2.1em, 24px);
    letter-spacing: 0;
}
.Button_small___Fogy {
    font-size: 16px;
    padding: max(0.55em, 14px) max(1.3em, 24px);
}
.Button_xsmall__CZbGo {
    font-size: 14px;
    padding: max(0.55em, 8px) max(1.3em, 18px);
}
.Button_xxsmall__sEwPr {
    font-size: 13px;
    font-weight: 500;
    padding: max(0.85em, 8px) max(1.3em, 20px);
    letter-spacing: 0;
}

.FeatureInfo_info__iXEJ6 {
    margin-top: 30px;
    display: flex;
    align-items: center;
}
.FeatureInfo_icon__F5oM3 {
    padding-right: 20px;
}
.FeatureInfo_icon__F5oM3 svg {
        height: 44px;
        width: auto;
    }
.FeatureInfo_text__q_9ml {
    font-family: var(--roboto);
    font-size: 16px;
    line-height: 1.4;
    font-style: italic;
    letter-spacing: 0.025em;
    color: var(--primary-text-color-200);
}
.FeatureInfo_text__q_9ml a {
        font-weight: 500;
    }

.More_more__5cpar {
    --roboto: 'RobotoFlex', sans-serif;
    text-transform: uppercase;
    font-family: var(--roboto);
    font-weight: 700;
    letter-spacing: 0.035em;
    display: inline-block;
    text-decoration: none;
    color: var(--primary-link-color);
    transition: color 0.3s ease-out;
}
    .More_more__5cpar:hover,
    .More_more__5cpar:active,
    .More_more__5cpar:focus {
        color: var(--primary-link-hover-color);
    }
    .More_more__5cpar:focus:not(:focus-visible) {
        outline: none;
    }
.More_big__XYq80 {
    font-size: 18px;
}
.More_small___ZiSb {
    font-size: 16px;
}
.More_blue__5ldwc {
    color: var(--primary-link-color);
}
.More_blue__5ldwc:hover,
    .More_blue__5ldwc:active,
    .More_blue__5ldwc:focus {
        color: var(--primary-link-hover-color);
    }

.More_feature-blue__0wf4g {
    font-family: var(--rubik);
    color: var(--primary-brand-color-600);
    font-weight: 700;
    text-transform: unset;
    letter-spacing: 0;
}

.More_feature-blue__0wf4g:hover,
    .More_feature-blue__0wf4g:active,
    .More_feature-blue__0wf4g:focus {
        color: #008bda;
        text-decoration: underline;
    }

.More_yellow__TRw5T {
    color: var(--secondary-brand-color-400);
}

.More_yellow__TRw5T:hover,
    .More_yellow__TRw5T:active,
    .More_yellow__TRw5T:focus {
        color: var(--secondary-brand-color-600);
    }
.More_orange__MqnoR {
    color: var(--orange-color);
}
.More_orange__MqnoR:hover,
    .More_orange__MqnoR:active,
    .More_orange__MqnoR:focus {
        color: var(--orange-color-hover);
    }
.More_white__Reph_ {
    color: #fff;
}
.More_white__Reph_:hover,
    .More_white__Reph_:active,
    .More_white__Reph_:focus {
        color: #f8fafc;
    }

.Split_split__hxFnG {
    margin-bottom: 50px;
}
    @media screen and (min-width: 980px) {.Split_split__hxFnG {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr;
        display: grid;
        margin-bottom: 80px
}
            .Split_split__hxFnG.Split_reverse__F6pA4 .Split_desc__DMjjZ {
                margin-left: 10px;
            }
            .Split_split__hxFnG.Split_reverse__F6pA4 .Split_picture__KGToP,
            .Split_split__hxFnG.Split_reverse__F6pA4 .Split_items__anwOl {
                order: -1;
            }
            .Split_split__hxFnG.Split_center__qG_XR .Split_desc__DMjjZ,
            .Split_split__hxFnG.Split_center__qG_XR .Split_picture__KGToP,
            .Split_split__hxFnG.Split_center__qG_XR .Split_items__anwOl {
                display: flex;
                flex-direction: column;
                justify-content: center;
            }
    }
    .Split_split__hxFnG:last-child {
        margin-bottom: 0;
    }
.Split_picture__KGToP {
    margin: 0;
    padding-top: 30px;
}
@media screen and (min-width: 980px) {
.Split_picture__KGToP {
        padding-top: 0
}
    }
.Split_picture__KGToP img {
        display: block;
        margin: 0 auto;
        max-width: 100%;
        height: auto;
    }
@media screen and (min-width: 980px) {
.Split_picture__KGToP img {
            position: relative
    }
        }
.Split_picture__KGToP > div {
        margin: 0 auto;
    }
.Split_items__anwOl {
    margin: 0;
    padding-top: 30px;
}
@media screen and (min-width: 980px) {
.Split_items__anwOl {
        padding-top: 0
}
    }

@layer defaults, theme;
@layer defaults {
    .Label_label__EGOQC {
        margin-bottom: 5px;
    }
        .Label_label__EGOQC span {
            border-radius: 15px;
            font-family: var(--roboto);
            font-size: 12px;
            font-weight: 700;
            letter-spacing: 0.1em;
            padding: 3px 12px;
            position: relative;
            z-index: 2;
            border: 1px solid #202730;
        }
            .Label_label__EGOQC span.Label_blue__446f_ {
                background: var(--primary-brand-color-400);
                color: #fff;
                border: 0;
            }
            .Label_label__EGOQC span.Label_yellow__ziowg {
                background: var(--secondary-brand-color-400);
                color: #202730;
                border: 0;
            }
            .Label_label__EGOQC span.Label_orange__eOUnQ {
                background: var(--orange-color);
                color: var(--orange-button-text);
                border: 0;
            }
            .Label_label__EGOQC span.Label_navy__YCdVk {
                background: #020730;
                color: #fff;
                border: 0;
            }
            .Label_label__EGOQC span.Label_green__dRR8L {
                background: var(--green-color);
                color: var(--green-button-text);
                border: 0;
            }
}

.Feature_feature__WypAE {
    margin-bottom: 70px;
    margin-top: 70px;
    scroll-margin-top: 138px;
}
    @media screen and (min-width: 900px) {.Feature_feature__WypAE {
        scroll-margin-top: 120px
}
    }
@media screen and (min-width: 980px) {
.Feature_split__e0ijW {
        display: flex;
        flex-direction: row;
        gap: 0 40px
}
    }
@media screen and (min-width: 980px) {
.Feature_split__e0ijW.Feature_reverse__K_82c {
            flex-direction: row-reverse
    }
        }
.Feature_details__9dw3M {
    position: relative;
    margin-bottom: 25px;
}
@media screen and (min-width: 980px) {
.Feature_details__9dw3M {
        flex-basis: 50%;
        min-width: 300px;
        margin-bottom: 0
}
    }
@media screen and (min-width: 980px) {
.Feature_details__9dw3M article {
            max-width: 470px
    }
        }
.Feature_picture__pxhZL {
    text-align: center;
}
@media screen and (min-width: 980px) {
.Feature_picture__pxhZL {
        text-align: initial;
        flex-basis: 50%;
        min-width: 300px
}
    }
.Feature_picture__pxhZL img {
        width: 100%;
        height: auto;
        min-width: 300px;
        max-width: -moz-fit-content;
        max-width: fit-content;
    }
.Feature_middle__RYcAg {
    align-self: center;
}
.Feature_start__3OrFF {
    align-self: start;
}
.Feature_end__s6UZh {
    align-self: end;
}

.FeaturesList_flip__EoJpA.FeaturesList_start__lDZ9F > div:nth-child(odd) > div {
        flex-direction: row-reverse;
    }
    .FeaturesList_flip__EoJpA.FeaturesList_end__52n6A > div:nth-child(even) > div {
        flex-direction: row-reverse;
    }
.FeaturesList_start__lDZ9F:not(.FeaturesList_flip__EoJpA) > div > div {
    flex-direction: row-reverse;
}
.FeaturesList_end__52n6A:not(.FeaturesList_flip__EoJpA) > div > div {
    flex-direction: row;
}

.Carousel_carousel__FlcfP {
    --base-fonts: 'Helvetica', 'Arial', sans-serif;
    --source: 'Source Sans 3', var(--base-fonts);
    --roboto: 'RobotoFlex', var(--base-fonts);
    position: relative;
}
    .Carousel_carousel__FlcfP ul {
        display: flex;
        list-style: none;
        padding: 0;
        margin: 0 auto;
        position: relative;
        gap: 40px;
        justify-content: center;
    }
    @media screen and (min-width: 900px) {
    .Carousel_carousel__FlcfP ul {
            gap: 90px
    }
        }
    .Carousel_carousel__FlcfP ul.Carousel_animate-from-right__z3K8R {
            animation-name: Carousel_carouselFromRight__bOu15;
            animation-duration: 0.3s;
        }
    .Carousel_carousel__FlcfP ul.Carousel_animate-from-left__SfQ83 {
            animation-name: Carousel_carouselFromLeft__oM_ri;
            animation-duration: 0.3s;
        }
    .Carousel_carousel__FlcfP li {
        align-self: stretch;
        margin-bottom: 20px;
        display: flex;
        justify-content: center;
        opacity: 0.23;
        pointer-events: none;
        flex: 1 0 75%;
        min-width: 300px;
    }
    @media screen and (min-width: 768px) {
    .Carousel_carousel__FlcfP li {
            min-width: 640px
    }
        }
    @media screen and (min-width: 900px) {
    .Carousel_carousel__FlcfP li {
            flex: 1 0 640px;
            min-width: 0
    }
        }
    .Carousel_carousel__FlcfP li.Carousel_current__HjUpL {
            z-index: 1;
            opacity: 1;
            filter: none;
            pointer-events: all;
        }
    .Carousel_carousel__FlcfP li.Carousel_current__HjUpL .Carousel_prev__Ww1SV,
            .Carousel_carousel__FlcfP li.Carousel_current__HjUpL .Carousel_next__jjN7v {
                display: block;
            }
    .Carousel_carousel__FlcfP .Carousel_prev__Ww1SV,
    .Carousel_carousel__FlcfP .Carousel_next__jjN7v {
        position: absolute;
        top: calc(50% - 10px);
        transform-origin: center;
        cursor: pointer;
        z-index: 1;
        padding: 10px;
    }
    .Carousel_carousel__FlcfP .Carousel_prev__Ww1SV svg, .Carousel_carousel__FlcfP .Carousel_next__jjN7v svg {
            width: 20px;
            height: 25px;
            opacity: 1;
            transform: none;
        }
    .Carousel_carousel__FlcfP .Carousel_prev__Ww1SV {
        left: -4px;
    }
    @media screen and (min-width: 768px) {
    .Carousel_carousel__FlcfP .Carousel_prev__Ww1SV {
            left: 20px;
    }
        }
    @media screen and (min-width: 900px) {
    .Carousel_carousel__FlcfP .Carousel_prev__Ww1SV {
            left: calc(50% - 365px);
    }
        }
    .Carousel_carousel__FlcfP .Carousel_prev__Ww1SV {
        transform: translate(-50%, -50%) rotateZ(90deg);
}
    .Carousel_carousel__FlcfP .Carousel_next__jjN7v {
        right: -4px;
    }
    @media screen and (min-width: 768px) {
    .Carousel_carousel__FlcfP .Carousel_next__jjN7v {
            right: 20px;
    }
        }
    @media screen and (min-width: 900px) {
    .Carousel_carousel__FlcfP .Carousel_next__jjN7v {
            right: calc(50% - 365px);
    }
        }
    .Carousel_carousel__FlcfP .Carousel_next__jjN7v {
        transform: translate(50%, -50%) rotateZ(-90deg);
}
@keyframes Carousel_carouselFromRight__bOu15 {
    from {
        transform: translateX(680px);
    }
    to {
        transform: translateX(0);
    }
}
@keyframes Carousel_carouselFromLeft__oM_ri {
    from {
        transform: translateX(-680px);
    }
    to {
        transform: translateX(0);
    }
}

.CarouselResources_carousel__olcw9 {
    position: relative;
}
    .CarouselResources_carousel__olcw9 ul {
        display: flex;
        list-style: none;
        padding: 0;
        margin: 0 auto;
        position: relative;
        gap: 40px;
        justify-content: center;
    }
    @media screen and (min-width: 900px) {
    .CarouselResources_carousel__olcw9 ul {
            gap: 90px
    }
        }
    .CarouselResources_carousel__olcw9 ul.CarouselResources_animate-from-right__CqRmt {
            animation-name: CarouselResources_carouselFromRight__nafHK;
            animation-duration: 0.3s;
        }
    .CarouselResources_carousel__olcw9 ul.CarouselResources_animate-from-left__oi_Ot {
            animation-name: CarouselResources_carouselFromLeft__OnaAf;
            animation-duration: 0.3s;
        }
    .CarouselResources_carousel__olcw9 li {
        align-self: stretch;
        margin-bottom: 20px;
        display: flex;
        opacity: 0.23;
        pointer-events: none;
        flex: 1 0 75%;
        min-width: 300px;
    }
    @media screen and (min-width: 768px) {
    .CarouselResources_carousel__olcw9 li {
            min-width: 560px
    }
        }
    @media screen and (min-width: 900px) {
    .CarouselResources_carousel__olcw9 li {
            flex: 1 0 560px;
            min-width: 0
    }
        }
    .CarouselResources_carousel__olcw9 li.CarouselResources_current__Lipo8 {
            z-index: 1;
            opacity: 1;
            filter: none;
            pointer-events: all;
        }
    .CarouselResources_carousel__olcw9 li.CarouselResources_current__Lipo8 .CarouselResources_prev__tDg3Q,
            .CarouselResources_carousel__olcw9 li.CarouselResources_current__Lipo8 .CarouselResources_next__rIbrg {
                display: block;
            }
    .CarouselResources_carousel__olcw9 .CarouselResources_prev__tDg3Q,
    .CarouselResources_carousel__olcw9 .CarouselResources_next__rIbrg {
        position: absolute;
        top: calc(50% - 10px);
        transform-origin: center;
        cursor: pointer;
        z-index: 1;
        padding: 10px;
    }
    .CarouselResources_carousel__olcw9 .CarouselResources_prev__tDg3Q svg, .CarouselResources_carousel__olcw9 .CarouselResources_next__rIbrg svg {
            width: 20px;
            height: 25px;
            opacity: 1;
            transform: none;
        }
    .CarouselResources_carousel__olcw9 .CarouselResources_prev__tDg3Q {
        left: -4px;
    }
    @media screen and (min-width: 768px) {
    .CarouselResources_carousel__olcw9 .CarouselResources_prev__tDg3Q {
            left: 20px;
    }
        }
    @media screen and (min-width: 900px) {
    .CarouselResources_carousel__olcw9 .CarouselResources_prev__tDg3Q {
            left: calc(50% - 325px);
    }
        }
    .CarouselResources_carousel__olcw9 .CarouselResources_prev__tDg3Q {
        transform: translate(-50%, -50%) rotateZ(90deg);
}
    .CarouselResources_carousel__olcw9 .CarouselResources_next__rIbrg {
        right: -4px;
    }
    @media screen and (min-width: 768px) {
    .CarouselResources_carousel__olcw9 .CarouselResources_next__rIbrg {
            right: 20px;
    }
        }
    @media screen and (min-width: 900px) {
    .CarouselResources_carousel__olcw9 .CarouselResources_next__rIbrg {
            right: calc(50% - 325px);
    }
        }
    .CarouselResources_carousel__olcw9 .CarouselResources_next__rIbrg {
        transform: translate(50%, -50%) rotateZ(-90deg);
}
@keyframes CarouselResources_carouselFromRight__nafHK {
    from {
        transform: translateX(680px);
    }
    to {
        transform: translateX(0);
    }
}
@keyframes CarouselResources_carouselFromLeft__OnaAf {
    from {
        transform: translateX(-680px);
    }
    to {
        transform: translateX(0);
    }
}

.ResourceCard_card__zPj1R {
    color: #202730;
    font-family: var(--roboto);
    background: #fff;
    border-radius: 6px;
    padding: 27px 22px;
    box-sizing: border-box;
    min-height: 302px;
    max-width: 560px;
    width: 100%;
    position: relative;
    box-shadow: 0 7px 45px 0 rgba(0, 0, 0, 0.1);
}
    @media screen and (min-width: 768px) {.ResourceCard_card__zPj1R {
        height: 100%;
        display: flex;
        flex-wrap: nowrap;
        padding: 38px 38px 30px
}
    }
    .ResourceCard_card__zPj1R:hover .ResourceCard_more__H59TI {
        color: var(--primary-link-hover-color);
    }
.ResourceCard_border__Nb0tw {
    box-shadow: none;
    border: 1px solid #cedbe5;
}
.ResourceCard_image__sD4vd {
    margin: 0 0 30px;
}
@media screen and (min-width: 768px) {
.ResourceCard_image__sD4vd {
        flex: 0 0 150px;
        margin: 0 30px 0 0
}
    }
.ResourceCard_image__sD4vd img {
        display: block;
        height: auto;
        max-width: 100%;
        margin: 0 auto;
        vertical-align: bottom;
    }
.ResourceCard_desc__Jfpr2 {
    text-align: center;
    margin-bottom: 13px;
}
@media screen and (min-width: 768px) {
.ResourceCard_desc__Jfpr2 {
        text-align: left
}
        .ResourceCard_desc__Jfpr2.ResourceCard_bottomlink__TFxgR {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
    }
.ResourceCard_desc__Jfpr2 h3 {
        font-family: var(--source);
        font-size: 23px;
        font-weight: 900;
        margin: 0 0 18px;
    }
.ResourceCard_desc__Jfpr2 h3 a {
            color: inherit;
            text-decoration: none;
        }
.ResourceCard_desc__Jfpr2 h3 a::before {
                content: '';
                bottom: 0;
                left: 0;
                right: 0;
                top: 0;
                position: absolute;
                z-index: 1;
            }
.ResourceCard_desc__Jfpr2 p {
        font-size: 16px;
        line-height: 1.44;
        margin: 0 0 23px;
    }
@media screen and (min-width: 768px) {
.ResourceCard_desc__Jfpr2 p {
            letter-spacing: 0.025em
    }
        }

.FreeTrialTestimonial_container__AWYSg {
    color: #fff;
    font-family: var(--roboto);
    margin: 0 -20px;
    max-width: 1140px;
    background: #00baff url(https://us-wn-g.gr-cdn.com/_next/static/media/maz.678b25bb.svg) center no-repeat;
    background-size: auto 100%;
}
    @media only screen and (min-width: 600px) {.FreeTrialTestimonial_container__AWYSg {
        margin: 0 auto;
        max-width: 600px;
        border-radius: 8px
}
    }
    @media only screen and (min-width: 980px) {.FreeTrialTestimonial_container__AWYSg {
        display: flex;
        flex-wrap: nowrap;
        max-width: 1140px
}
    }
    .FreeTrialTestimonial_container__AWYSg form {
        width: 100%;
        max-width: 516px;
        margin: 30px auto 0;
        position: relative;
    }
    @media only screen and (min-width: 980px) {
    .FreeTrialTestimonial_container__AWYSg form {
            display: flex;
            margin-left: 0
    }
        }
    .FreeTrialTestimonial_container__AWYSg form fieldset {
            margin: 0;
            padding: 0;
            border: 0;
        }
    @media only screen and (min-width: 980px) {
    .FreeTrialTestimonial_container__AWYSg form fieldset {
                flex-grow: 1;
                margin-right: -30px
        }
            }
    .FreeTrialTestimonial_container__AWYSg form input {
            font-family: var(--roboto);
            border: none;
            height: 60px;
            width: 100%;
            padding: 0 35px 0 30px;
            box-sizing: border-box;
            color: #202730;
            border-radius: 100px;
            margin-bottom: 20px;
            font-size: 20px;
            font-weight: 500;
        }
    @media only screen and (min-width: 980px) {
    .FreeTrialTestimonial_container__AWYSg form input {
                border-radius: 100px 0 0 100px;
                flex-grow: 1;
                margin-bottom: 0;
        }
            }
    .FreeTrialTestimonial_container__AWYSg form input:focus {
                outline: none;
            }
    .FreeTrialTestimonial_container__AWYSg form input {
            --placeholder-text-color: #7a90a1;
}
    .FreeTrialTestimonial_container__AWYSg form small {
            position: absolute;
            bottom: -10px;
            transform: translateY(100%);
            color: #fff;
            font-size: 13px;
            font-family: var(--roboto);
            letter-spacing: 0.05em;
            font-weight: 400;
        }
.FreeTrialTestimonial_formwrapper__V4aWs {
    flex-grow: 1;
    box-sizing: border-box;
    padding: 30px 20px 120px;
    text-align: center;
    margin: 0;
    min-height: 382px;
}
@media screen and (min-width: 768px) {
.FreeTrialTestimonial_formwrapper__V4aWs {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 30px 20px 120px
}
    }
@media only screen and (min-width: 980px) {
.FreeTrialTestimonial_formwrapper__V4aWs {
        padding: 16px 100px 22px 72px;
        text-align: left
}
    }
.FreeTrialTestimonial_title__rPN3F {
    font-family: var(--source);
    font-weight: 900;
    line-height: 1.25;
    font-size: 40px;
    letter-spacing: 0.02em;
    margin: 6px 0 0;
}
.FreeTrialTestimonial_text__nXlw8 {
    font-family: var(--roboto);
    font-weight: 500;
    line-height: 1.25;
    font-size: 18px;
    letter-spacing: 0.02em;
    margin: 0 0 10px;
    padding: 12px 0 0;
}
.FreeTrialTestimonial_image__4h43D {
    padding: 100px 20px 20px;
    background: #00a2ff;
    position: relative;
    flex-grow: 1;
    box-sizing: border-box;
    border-radius: 0 0 8px 8px;
}
@media only screen and (min-width: 980px) {
.FreeTrialTestimonial_image__4h43D {
        border-radius: 0 8px 8px 0;
        padding: 20px 30px 20px 115px;
        display: flex;
        align-items: center;
        max-width: 350px
}
    }
@media only screen and (min-width: 1060px) {
.FreeTrialTestimonial_image__4h43D {
        max-width: 436px;
        padding-right: 50px
}
    }
.FreeTrialTestimonial_image__4h43D img {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(calc(4px - 50%), calc(6px - 50%));
    }
@media only screen and (min-width: 980px) {
.FreeTrialTestimonial_image__4h43D img {
            top: 50%;
            left: 0
    }
        }
.FreeTrialTestimonial_blockquote__xNHps {
    text-align: left;
    margin: 0;
}
.FreeTrialTestimonial_cite__kDaSd {
    font-size: 18px;
    margin: 0 0 28px;
    font-weight: 500;
    font-style: italic;
    line-height: 1.6;
    letter-spacing: 0.015em;
}
.FreeTrialTestimonial_medium__Oq_C1 .FreeTrialTestimonial_cite__kDaSd {
        font-size: 22px;
        line-height: 1.3;
        letter-spacing: 0;
    }
.FreeTrialTestimonial_big__k8DiN .FreeTrialTestimonial_cite__kDaSd {
        font-size: 27px;
        line-height: 1.3;
        letter-spacing: 0;
    }
.FreeTrialTestimonial_author__VEKZK {
    margin: 0;
    padding: 0;
}
.FreeTrialTestimonial_author__VEKZK p {
        margin: 0;
    }
.FreeTrialTestimonial_author__VEKZK strong {
        font-size: 18px;
        display: block;
        margin-bottom: 8px;
    }
.FreeTrialTestimonial_author__VEKZK span {
        font-size: 15px;
        letter-spacing: 0.02em;
    }
.FreeTrialTestimonial_btn__50o8I {
    cursor: pointer;
    font-family: var(--roboto);
    box-sizing: border-box;
    text-decoration: none;
    text-transform: uppercase;
    transition: background 0.2s ease-in-out;
    border-radius: 50px;
    padding: 19px 38px;
    border: 0;
    font-weight: 700;
    font-size: 18px;
    display: inline-block;
    height: 100%;
    letter-spacing: 0;
    white-space: nowrap;
    line-height: 1;
    color: #202730;
    background: var(--secondary-brand-color-400);
}
@media only screen and (min-width: 980px) {
.FreeTrialTestimonial_btn__50o8I {
        padding: 14px
}
    }
.FreeTrialTestimonial_btn__50o8I:hover {
        background: var(--secondary-brand-color-600);
    }
.FreeTrialTestimonial_error__V0sS1 {
    margin-bottom: 20px;
    font-size: 14px;
    background: #ec5c5c;
    border-radius: 3px;
    padding: 0.3em 0.7em;
}
@media only screen and (min-width: 980px) {
.FreeTrialTestimonial_error__V0sS1 {
        position: absolute;
        margin-top: 5px;
        margin-left: 30px
}
    }

.BlueArticle_blue__FptNQ {
    margin: 0 0 40px;
    padding: 20px;
    box-sizing: border-box;
    border: 2px solid #00baff;
    color: #202730;
    font-family: var(--roboto);
    background: #f7fdff;
    border-radius: 6px;
    text-align: left;
}
    @media screen and (min-width: 768px) {.BlueArticle_blue__FptNQ {
        padding: 38px 58px
}
    }
    .BlueArticle_blue__FptNQ h3 {
        font-weight: 700;
        font-family: var(--source);
        font-size: 25px;
        letter-spacing: 0.025em;
        margin: 0 0 28px;
    }
    @media screen and (min-width: 768px) {
    .BlueArticle_blue__FptNQ h3 {
            font-size: 30px
    }
        }
    .BlueArticle_blue__FptNQ p {
        font-family: var(--roboto);
        font-size: 18px;
        font-weight: 400;
        letter-spacing: 0.03em;
        line-height: 1.5;
        margin: 0 0 30px;
    }
    .BlueArticle_blue__FptNQ p:last-child {
            margin-bottom: 0;
        }

.Manual_manual___gUq_ {
    margin: 0;
    padding: 0;
    list-style: none;
    counter-reset: counter;
    text-align: left;
    overflow: hidden;
}
    @media screen and (min-width: 980px) {.Manual_manual___gUq_ {
        padding-top: 10px
}
    }
@media screen and (min-width: 980px) {
    .Manual_left__y03d3 {
        text-align: left;
    }
    .Manual_right__JeY5_ {
        text-align: right;
    }
}
.Manual_step__cC26_ {
    margin-bottom: 50px;
}
@media screen and (min-width: 980px) {
.Manual_step__cC26_ {
        margin-bottom: 70px
}
    }
.Manual_step__cC26_:last-child {
        margin-bottom: 0;
    }
@media screen and (min-width: 980px) {
            .Manual_step__cC26_:last-child h3::before {
                content: '';
                position: absolute;
                top: 42px;
                left: calc(100% + 66px);
                height: 700px;
                width: 2px;
                background: #f7fdff;
            }
        }
@media screen and (min-width: 980px) {
        .Manual_step__cC26_:first-child h3::after {
            content: '';
            position: absolute;
            top: 44px;
            left: calc(100% + 66px);
            height: 2000px;
            width: 2px;
            background: url(https://us-wn-g.gr-cdn.com/_next/static/media/dot.0363060b.svg) 0 center repeat-y;
            background-size: 2px 12px;
        }
    }
@media screen and (min-width: 980px) {
.Manual_wrap___hliR {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr;
        grid-gap: 0 55px;
        display: grid
}
    }
.Manual_bullets__l5MaH h3::before {
            width: 50px;
            height: 50px;
            counter-increment: counter;
            content: counter(counter);
            font-family: var(--source);
            font-size: 22px;
            font-weight: 700;
            color: #fff;
            background: var(--primary-btn-bg-color);
            border-radius: 30px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            margin: 0 10px 0 0;
        }
@media screen and (min-width: 980px) {
            .Manual_bullets__l5MaH h3::before {
                margin: 0;
                position: absolute;
                top: -3px;
                left: calc(100% + 41px);
            }
        }
.Manual_title__tYxSX {
    position: relative;
}
.Manual_title__tYxSX svg {
        width: 50px;
        height: 50px;
        display: inline-block;
        vertical-align: middle;
        margin: 0 10px 0 0;
    }
@media screen and (min-width: 980px) {
.Manual_title__tYxSX svg {
            width: 70px;
            height: 70px;
            margin: 0;
            position: absolute;
            top: -12px;
            left: calc(100% + 32px)
    }
        }
.Manual_texts__e1xG7,
.Manual_picture__Gk26b {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.Manual_texts__e1xG7 {
    margin-bottom: 30px;
}
@media screen and (min-width: 980px) {
.Manual_texts__e1xG7 {
        max-width: 444px;
        margin: 0 38px 0 auto
}
    }
.Manual_texts__e1xG7 h3 {
        font-weight: 700;
        font-family: var(--source);
        font-size: 25px;
        letter-spacing: 0;
        margin: 0 0 20px;
    }
@media screen and (min-width: 768px) {
.Manual_texts__e1xG7 h3 {
            font-size: 30px
    }
        }
.Manual_texts__e1xG7 p {
        font-family: var(--source);
        font-size: 20px;
        font-weight: 400;
        letter-spacing: 0;
        line-height: 1.5;
        margin: 0 0 30px;
    }
.Manual_picture__Gk26b {
    margin: 0;
}
@media screen and (min-width: 980px) {
.Manual_picture__Gk26b {
        margin-left: 20px
}
    }
.Manual_picture__Gk26b img {
        display: block;
        margin: 0 auto;
        max-width: 100%;
        height: auto;
    }
@media screen and (min-width: 980px) {
.Manual_picture__Gk26b img {
            padding-right: 20px
    }
        }

